import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SettingsPage } from '@pages';

// Will be mounted on /organization/settings
// In shell we can use (isActiveRoute('organization/settings'), see settings/users
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<SettingsPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export { App };
