import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar, useActiveOrganization, useSPARouter, useGTM } from '@didomi/utility-react';
import { useMediaQuery } from '@didomi/helpers-react';
import {
  DidomiTextInputField,
  DidomiButton,
  DidomiTooltip,
  DidomiIcon,
  DidomiModal,
  DidomiModalHeader,
  DidomiModalActions,
  DidomiLoader,
  DidomiIllustration,
} from '@didomi/ui-atoms-react';
import { tw } from '@didomi/ui-foundation';
import { usePatchOrganization } from '@hooks';
import { OrganizationInput } from '@types';
import { SnackbarOptions } from '@didomi/utility/dist/typings/src/snackbar/models/snackbar.model';

const PatchOrganizationSchema = Yup.object().shape({
  id: Yup.string().required(),
  name: Yup.string().required('Organization name is required'),
  website: Yup.string().url('Please enter a valid URL (e.g. https://example.com)').required('Company URL is required'),
});

const SettingsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const { pushEventToDataLayer } = useGTM();
  const { navigateTo } = useSPARouter();
  const { organization, updateActiveOrganization } = useActiveOrganization();
  const { displaySnackbar } = useSnackbar();
  const isMobileLayout = useMediaQuery('(max-width: 768px)');
  const isSmallLayout = useMediaQuery('(max-width: 992px)');

  const { mutate: mutateOrganization, isLoading } = usePatchOrganization({
    onSuccess({ data: updatedOrg }) {
      if (isModalOpen) {
        setIsModalOpen(false);
        updateActiveOrganization(updatedOrg);
        navigateTo('dashboard');
      } else {
        updateActiveOrganization(updatedOrg);
        displaySnackbar('The organization has been updated', snackbarOptions);
      }
    },
    onError() {
      displaySnackbar('The organization could not be updated. Please try again or contact support@didomi.io', errorSnackbarOptions);
    },
  });

  const handleFormSubmit = (values: OrganizationInput) => {
    if (organization.website !== values.website) {
      // the modal will handle the submission further
      setIsModalOpen(true);
      setFormValues(values);
    } else {
      pushEventToDataLayer('organization-updated', { self_service: organization?.self_register });
      mutateOrganization(values);
    }
  };

  const initialValues = {
    id: organization?.id,
    name: organization?.name,
    website: organization?.website,
  };

  const handleModalConfirm = () => {
    pushEventToDataLayer('organization-url-change', { self_service: organization?.self_register });
    mutateOrganization(formValues);
  };

  const handleModalCancel = (resetForm: () => void) => {
    resetForm();
    setIsModalOpen(false);
  };

  return (
    <div id="org-settings-page" className="pl-s pr-s pt-l md:(pr-0 pl-l)">
      <div id="org-settings-header" className="mb-xxl text-center md:text-left!">
        <h1 className="h1-catchline text-primary-blue-6">Organization settings</h1>
        <p className="body-big-light text-primary-blue-6">Update your organization name and website URL</p>
      </div>
      <div id="org-settings-main" className="flex justify-between	">
        <div id="org-settings-form" className="w-full md:max-w-[400px]! xl:w-[600px]!">
          <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} validationSchema={PatchOrganizationSchema} onSubmit={handleFormSubmit}>
            {({ handleSubmit, resetForm, values }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <DidomiTextInputField disabled name="id" label="Organization ID" value={values.id} className="mb-xs">
                    <DidomiTooltip
                      slot="description"
                      placement={/* istanbul ignore next */ isSmallLayout ? 'top' : 'right'}
                      trigger="click mouseenter focus"
                      content="Your organization ID will be requested when communicating with the Didomi support team "
                      variant="helper"
                      className="ml-xxs"
                      attachTo="org-settings-page"
                      data-testid="organization-settings-id-field-tooltip"
                    >
                      <DidomiIcon name="helper-text" className="align-middle"></DidomiIcon>
                    </DidomiTooltip>
                  </DidomiTextInputField>
                  <DidomiTextInputField name="name" label="Organization name" value={values.name} className="mb-xs"></DidomiTextInputField>
                  <DidomiTextInputField name="website" label="Website URL" value={values.website}></DidomiTextInputField>
                  <DidomiButton type="submit" fullWidth={isMobileLayout} className="mb-xxl mt-xs">
                    Save changes
                  </DidomiButton>
                </form>
                <DidomiModal isOpen={isModalOpen} data-testid="organization-settings-confirm-modal">
                  <div
                    className={tw({
                      hidden: !isLoading,
                    })}
                  >
                    <DidomiLoader className="mb-xs flex justify-center"></DidomiLoader>
                    <DidomiModalHeader modalTitle="We are relaunching your compliance report..." modalSubTitle="Please wait"></DidomiModalHeader>
                  </div>

                  <div
                    className={tw('max-w-[375px]', {
                      hidden: isLoading,
                    })}
                  >
                    <DidomiIcon name="warning" style={{ marginBottom: '16px' }}></DidomiIcon>
                    <DidomiModalHeader
                      modalTitle="The compliance test will relaunch"
                      modalSubTitle="Please pay attention"
                      modalDescription="Changing the website URL of your organization will delete your existing compliance report and launch a new one."
                    ></DidomiModalHeader>
                    <DidomiModalActions>
                      <DidomiButton data-tracking="organization-url-change-cancel" className="w-[135px]" variant="secondary" onClick={() => handleModalCancel(resetForm)}>
                        Cancel
                      </DidomiButton>
                      <DidomiButton data-tracking="organization-url-change" className="w-[135px]" onClick={handleModalConfirm}>
                        Relaunch
                      </DidomiButton>
                    </DidomiModalActions>
                  </div>
                </DidomiModal>
              </>
            )}
          </Formik>
        </div>
        {!isSmallLayout && (
          <div id="org-settings-illustration" className="mx-auto xxl:mr-[128px]!">
            <DidomiIllustration data-cy="organization-settings-illustration" name="organization-settings" />
          </div>
        )}
      </div>
    </div>
  );
};

const snackbarOptions: SnackbarOptions = {
  variant: 'message',
  duration: 5000,
  action: {
    name: 'Ok',
    closeInAction: true,
  },
};

const errorSnackbarOptions: SnackbarOptions = {
  variant: 'error',
  permanent: true,
  action: {
    name: 'Close',
    closeInAction: true,
  },
};

export { SettingsPage };
