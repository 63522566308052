import { useAuthToken, useAuthMethods } from '@didomi/utility-react';
import { addAxiosResponseInterceptors } from '@didomi/helpers/dist/response-interceptors/axios/axios-response-interceptors';
import { useMutation, UseMutationOptions } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Organization, OrganizationInput } from '@types';

/**
 * Update the organization
 * */
export const usePatchOrganization = (options: UseMutationOptions<AxiosResponse<Organization>, AxiosError, Organization>) => {
  const token = useAuthToken();
  const { logout } = useAuthMethods();
  return useMutation(async (organizationInput: OrganizationInput) => {
    const axiosWithInterceptors = addAxiosResponseInterceptors(axios, { onExpiredToken: logout, onForbidden: logout });
    return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}/organizations/${organizationInput.id}`, organizationInput, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, options);
};
